@import 'tools/variables';
@import 'tools/mixins';

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:focus {
        outline: 0;
    }
}

.useful {
    display: inline-block;
    position: relative;
    border-radius: $border-radius;
    cursor: pointer;
    color: $light-gray;

    &__icon {
        margin-right: 4px;
    }

    &__count {
        @include font('Roboto', 700);
        font-size: 10px;
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 22px;
        border: 3px solid $white;
        color: $white;
        border-radius: $border-radius;
        font-style: normal;
        line-height: 1.8;
    }

    span {
        font-size: 12px;
    }

    &--positive & {
        &__count {
            background-color: $success;
            &:hover {
                background-color: $success-accent;
            }
        }

        &__info {
            &:hover {
                color: $success;
            }
        }
    }

    &--positive-active {
        color: $success;
    }

    &--negative & {
        &__count {
            background-color: $danger;

            &:hover {
                background-color: $danger-accent;
            }
        }

        &__info {
            &:hover {
                color: $danger;
            }
        }
    }

    &--negative-active {
        color: $danger;
    }

    &--small {
        background-color: $light-gray;
        border-radius: $border-radius;
        color: $white;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--small#{&}--positive {
        &:hover {
            background-color: $success;
        }
    }

    &--small#{&}--positive-active {
        background-color: $success;
    }

    &--small#{&}--negative-active {
        background-color: $danger;
    }

    &--small#{&}--negative {
        &:hover {
            background-color: $danger;
        }
    }

    &--small & {
        &__icon {
            margin-right: 0;
        }
    }
}

.button-icon {
    padding: 0;
    width: 45px;
    height: 45px;
    min-width: 45px;
    border-radius: $border-radius;

    &__icon {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: perspective(1px) translateY(-50%);
        display: block;
        text-align: center;
    }

    &--small {
        width: 37px;
        height: 37px;
        min-width: 37px;
    }

    &--xsmall {
        width: 32px;
        height: 32px;
        min-width: 32px;
    }

    &--xxsmall {
        width: 26px;
        height: 26px;
        min-width: 26px;
    }

    &:active {
        top: 0px;
    }

    &.button--white {
        border-bottom: 1px solid var(--light-blue);
    }
}

.button-link {
    @include font('Roboto', 700);
    font-size: 14px;
    color: var(--dark-blue);
    cursor: pointer;
    display: inline-block;

    em {
        margin-right: 8px;
    }

    &:hover {
        color: var(--dark-blue);
        text-decoration: underline;
    }

    &.white {
        color: $white;
    }

    &.bold {
        @include font('Roboto', 700);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font('Roboto');
        font-size: 14px;
    }

    &--small {
        font-size: 12px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 12px;
        }
    }

    &--lead {
        font-size: 15px;
        font-weight: 300;
    }

    &--light-gray {
        color: $light-gray;

        &:hover {
            color: $light-gray-accent;
        }
    }

    &--black {
        color: $black;

        &:hover {
            color: $black;
        }
    }

    &--warning {
        color: $warning;

        &:hover {
            color: $warning;
        }
    }

    &--white {
        color: $white;

        &:hover {
            color: $light-gray-accent;
        }
    }

    &--dark-green {
        color: var(--dark-green);

        &:hover {
            color: var(--dark-green-accent);
        }
    }

    &--underline {
        text-decoration: underline;
    }

    &--bold {
        @include font('Roboto', 700);
    }

    &--like,
    &--dislike,
    &--reaction {
        &:hover {
            text-decoration: none;
        }
    }

    &--like {
        &:hover {
            color: $success;
        }
    }

    &--dislike {
        &:hover {
            color: $danger;
        }
    }

    &--reaction {
        &:hover {
            color: var(--dark-blue);
        }
    }

    &--icon {
        em {
            margin-right: 4px;
        }

        &:hover {
            text-decoration: none;

            span {
                text-decoration: underline;
            }
        }
    }

    &--loadmore {
        @include font('Roboto', 700);
        color: $black;

        .icon {
            margin-right: 4px;
        }

        &:hover {
            text-decoration: none;
            color: $black;

            span {
                text-decoration: underline;
            }
        }
    }
}
