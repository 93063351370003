.lightbox-modal {
    .modal-dialog {
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .modal-page__body {
        padding: 0 !important;
    }
}
