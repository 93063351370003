@import 'tools/variables';
@import 'tools/mixins';

.forum-topic-reaction {
    &__image {
        position: relative;
        max-width: fit-content;

        .forum-topic-reaction__img {
            max-width: 100%;
        }
    }
}

.forum-reaction__content {
    .content {
        a {
            @include font('roboto');
            font-weight: 700;
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            max-height: 400px;
        }

        p {
            margin-bottom: 0;
            min-height: 16px;
        }

        .card__quote,
        .quote {
            background-color: var(--light-blue);
            padding: 16px;
            border-left: 3px solid $light-gray;
            margin-bottom: 4px;
            position: relative;

            @include media-breakpoint-up(lg) {
                padding: 16px 48px 16px 16px;
            }

            @include media-breakpoint-up(xxl) {
                margin-bottom: 8px;
            }

            &__delete-icon {
                @include transition(opacity 0.2s ease);
                padding: 16px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            strong, b {
                a {
                    color: $black;
                    font-size: inherit;
                }
            }

            p {
                margin: 0;
            }
        }
    }
}

.forum-useful-links {
    .bullet-list-item {
        border-radius: $border-radius;
        background-color: $white;
        border-radius: $border-radius;
        height: 45px;
        color: $black;
        line-height: 18px;
        list-style: none;
        width: fit-content;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border;

        > a {
            @include font('robotoregular');
            display: block;
            font-size: 12px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 16px;
        }

        &:hover {
            a {
                font-weight: 600;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
