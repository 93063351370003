@use 'sass:math';

// scss file for mixins
// ----
// rem mixins
// ----
$rem-base: 16px !default;

/**
* $base-font-size: 100% !default;
* $base-line-height is 24px while $base-font-size is 16px
* $base-line-height: 150%;
*
* It strips the unit of measure and returns it
*/
@function strip-unit($num) {
    @return math.div($num, ($num * 0 + 1));
}

/**
* New Syntax, allows to optionally calculate on a different base value to counter compounding effect of em\'s.
* Call with 1, 2, 3 or 4 parameters, \'px\' is not required but supported
* rem-calc(10 20 30px 40);
* Space delimited, if you want to delimit using comma\'s, wrap it in another pair of brackets
* rem-calc((10, 20, 30, 40px));
* Optionally call with a different base (eg: 8px) to calculate em.
* rem-calc(16px 32px 48px, 8px);
* If you require to comma separate your list
* rem-calc((16px, 32px, 48), 8px);
*/
@function convert-to-rem($value, $base-value: $rem-base) {
    $value: math.div(strip-unit($value), strip-unit($base-value)) * 1rem;

    @if ($value==0rem) {
        $value: 0;
    } // Turn 0em into 0

    @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);
    @if $max==1 {
        @return convert-to-rem(nth($values, 1), $base-value);
    }
    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append(
            $remValues,
            convert-to-rem(nth($values, $i), $base-value)
        );
    }
    @return $remValues;
}

@function random-range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

@mixin font($value, $weight: 400) {
    font-family: $value, Arial, Helvetica, 'sans-serif' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 1px transparent;
    text-rendering: optimizeLegibility;
    letter-spacing: 0;
    font-weight: $weight;
}

@mixin cubic-transition($type: all) {
    transition: $type 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95); /* easeInOutSine */
    transition-timing-function: cubic-bezier(
        0.445,
        0.05,
        0.55,
        0.95
    ); /* easeInOutSine */
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Calculate Rem
@function calculateRem($size) {
    $remSize: math.div($size, 16);
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size + px;
    font-size: calculateRem($size);
}

// Box shadow
@mixin box-shadow($value...) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
    -webkit-transform: translateZ(0);
    // Rendering forceren naar GPU voor Safari/WebKit om traagheid te voorkomen
    // https://stackoverflow.com/questions/5811974/text-shadow-and-other-css3-causes-scroll-lag
}

// Drop shadow
@mixin drop-shadow($value...) {
    -webkit-filter: drop-shadow($value);
    -moz-filter: drop-shadow($value);
    filter: drop-shadow($value);
    -webkit-transform: translateZ(0);
    // Rendering forceren naar GPU voor Safari/WebKit om traagheid te voorkomen
    // https://stackoverflow.com/questions/5811974/text-shadow-and-other-css3-causes-scroll-lag
}

// Text shadow
@mixin text-shadow($value...) {
    -webkit-filter: text-shadow($value);
    -moz-filter: text-shadow($value);
    filter: text-shadow($value);
    -webkit-transform: translateZ(0);
    // Rendering forceren naar GPU voor Safari/WebKit om traagheid te voorkomen
    // https://stackoverflow.com/questions/5811974/text-shadow-and-other-css3-causes-scroll-lag
}

// Mixin gradients
@mixin linear-gradient($from, $to) {
    background: $to;
    background: linear-gradient(to bottom, $from 0%, $to 100%);
}

@mixin diagonal-gradient($from, $to) {
    background: $to;
    background: linear-gradient(45deg, $from 0%, $to 100%);
}

@mixin transform($transforms) {
    will-change: transform;
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin animation($animation) {
    -webkit-animation: $animation;
    -moz-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin font-awesome() {
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 900;
    font-variant: normal;
    text-transform: none;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin user-select($value) {
    user-select: $value;
    -webkit-user-select: $value;
    -khtml-user-select: $value;
    -webkit-touch-callout: $value;
    -moz-user-select: $value;
    -o-user-select: $value;
}

@mixin transparent-color($type, $h, $s, $l, $opacity) {
    @if ($type == background-color) {
        background-color: hsla(
            $h,
            $s,
            calc(#{$l} - #{$opacity + '%'}),
            #{'0.' + $opacity}
        );
    } @else {
        color: hsla(
            $h,
            $s,
            calc(#{$l} - #{$opacity + '%'}),
            #{'0.' + $opacity}
        );
    }
}

@mixin medias($map, $key) {
    @each $keyMap, $valueMap in $map {
        @media all and (min-width: map-get($valueMap, min-width)) {
            #{$key}: map-get($valueMap, $key);
        }
    }
}
