@import 'tools/variables';
@import 'tools/mixins';

// Defaults
ul {
    padding: 0;
    margin: 0;
    line-height: 1.8;
    list-style: none;
}

ol {
    padding: 0;
    margin: 0;
    list-style-position: inside;
    list-style: none;
    counter-reset: line;

    li {
        padding-left: 32px;
        margin-bottom: 4px;

        &:before {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 22px;
            height: 22px;
            line-height: 20px;
            background-color: var(--dark-blue);
            border-radius: $border-radius;
            color: #fff;
            text-align: center;
            counter-increment: line;
            content: counter(line);
            font-size: 12px;
        }
    }
}

li {
    position: relative;
}

// List Class
.list {
    &__header {
        @include font('minorabold');
        font-size: 14px;
        background-color: var(--dark-blue);
        color: $white;
        padding: 0 16px;

        &:hover {
            background-color: var(--dark-blue-accent);
        }

        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }
    }

    &__item {
        padding: 8px 16px;
        list-style: none;
        font-size: 13px;
        line-height: 1.2;
        background-color: $white;

        &:nth-child(odd) {
            background-color: var(--light-blue);
        }
    }
}
