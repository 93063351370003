.chat-message {
    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &--single {
        span {
            a {
                color: var(--dark-blue);
            }
        }
    }

    &__announcement {
        display: flex;

        img {
            max-width: 125px;
            max-height: 125px;
            object-fit: cover;
        }

        a {
            color: var(--dark-green);
        }

        @include media-breakpoint-down(xxl) {
            flex-direction: column;
        }
    }
}
