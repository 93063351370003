@use 'sass:map';

@import 'tools/variables';
@import 'tools/mixins';

@mixin make-container-fluid-side-max-widths(
    $side,
    $max-widths: (
        lg: map.get($container-max-widths, 'lg'),
        xl: map.get($container-max-widths, 'xl'),
        xxl: map.get($container-max-widths, 'xxl')
    ),
    $breakpoints: (
        lg: map.get($grid-breakpoints, 'lg'),
        xl: map.get($grid-breakpoints, 'xl'),
        xxl: map.get($grid-breakpoints, 'xxl')
    )
) {
    margin-#{$side}: 0;
    padding-#{$side}: 0;

    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: calc(#{$container-max-width} / 2);
        }
    }
}

.container-fluid-left {
    @include make-container();
}

.container-fluid-right {
    @include make-container();
}

@include media-breakpoint-up(lg) {
    .container-fluid-left {
        @include make-container-fluid-side-max-widths('left');
    }

    .container-fluid-right {
        @include make-container-fluid-side-max-widths('right');
    }
}
