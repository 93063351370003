// Uncategorized deep styling

.header {
    &__slogan {
        a {
            color: $white;
        }
    }
}

.accordion-panel {
    &__content {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.bookmaker-review-point,
.tip-competition-information {
    &__description {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

// Form stepper line for new tip modal
// TODO: Remove when new betting tip modal is live
.form-stepper-line {
    width: 1px;
    border: 1px dashed $black;
    position: absolute;
    top: 24px;
    left: 11px;
    z-index: 2;
}

.form-stepper-line-opacity {
    width: 1px;
    border: 1px dashed $light-gray;
    position: absolute;
    top: 24px;
    left: 11px;
    z-index: 1;
}

// Static blocks styling
.static-table-of-contents {
    padding: 8px;
    background-color: $border;
    border: 1px solid $black;
    margin-bottom: 1rem;
}

.tooltip,
.tooltip-arrow {
    position: absolute;
}

// Highlighted text dynamic render
.highlighted-search-text {
    font-weight: bold;
    font-size: inherit;
}
