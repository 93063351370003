// Scrollbars
.no-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.horizontal-scrollbar {
    &::-webkit-scrollbar {
        height: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $border;
        border-radius: $border-radius;
    }

    &::-webkit-scrollbar-thumb {
        background: $dark-blue;
        border-radius: $border-radius;
        min-width: 24px;

        &:hover {
            background: $dark-blue-accent;
        }
    }
}

.vertical-scrollbar {
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $border;
        border-radius: $border-radius;
    }

    &::-webkit-scrollbar-thumb {
        background: $dark-blue;
        border-radius: $border-radius;
        min-height: 24px;

        &:hover {
            background: $dark-blue-accent;
        }
    }

    &--chat-light {
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $white;
            border-radius: unset;
        }

        &::-webkit-scrollbar-thumb {
            background: $light-gray;

            &:hover {
                background: $light-gray-accent;
            }
        }
    }

    &--chat-dark {
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $dark-blue-accent;
            border-radius: unset;
        }

        &::-webkit-scrollbar-thumb {
            background: $dark-gray;

            &:hover {
                background: $dark-gray-accent;
            }
        }
    }
}
