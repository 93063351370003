.body {
    @include font('Roboto');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    font-size: 16px;

    @include media-breakpoint-down(lg) {
        font-size: 15px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @include font('minorabold');
    color: $dark-blue;
    position: relative;
    margin-bottom: 0;

    span,
    a {
        font-size: inherit;
        line-height: inherit;
    }
}

h1,
.h1 {
    @include font-size(32);

    @include media-breakpoint-down(lg) {
        @include font-size(24);
    }

    @include media-breakpoint-down(md) {
        @include font-size(22);
    }
}

h2,
.h2 {
    @include font-size(26);

    @include media-breakpoint-down(lg) {
        @include font-size(22);
    }

    @include media-breakpoint-down(md) {
        @include font-size(20);
    }
}

h3,
.h3 {
    @include font-size(22);

    @include media-breakpoint-down(lg) {
        @include font-size(20);
    }

    @include media-breakpoint-down(md) {
        @include font-size(18);
    }
}

h4,
.h4 {
    @include font-size(20);

    @include media-breakpoint-down(lg) {
        @include font-size(18);
    }

    @include media-breakpoint-down(md) {
        @include font-size(16);
    }
}

h5,
.h5 {
    @include font-size(18);

    @include media-breakpoint-down(lg) {
        @include font-size(16);
    }

    @include media-breakpoint-down(md) {
        @include font-size(14);
    }
}

h6,
.h6 {
    @include font-size(16);

    @include media-breakpoint-down(lg) {
        @include font-size(14);
    }

    @include media-breakpoint-down(lg) {
        @include font-size(12);
    }
}

// Strong
b,
strong {
    font-weight: 600;
    font-size: inherit;
    line-height: inherit;
}

// Links
a {
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    text-decoration: none;
    color: $black;

    &:hover {
        color: unset;
        text-decoration: none;
    }
}

span {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;

    a {
        font-size: inherit;
    }
}

label {
    font-size: 12px;
    line-height: 18px;

    a {
        font-size: inherit;
    }
}

// Paragraphs
p {
    font-size: 15px;
    line-height: 24px;
    min-height: 16px;
    margin-bottom: 0;

    a {
        font-size: inherit;
    }

    span {
        font-size: inherit;
        line-height: inherit;
    }
}
