@import 'tools/variables';
@import 'tools/mixins';

iframe {
    &.ql-video {
        width: 100%;
        display: block;
        min-height: 300px;
        max-width: 525px;
        margin-top: 24px;
    }
}

.ql-toolbar {
    border: none !important;
    border-bottom: 1px solid $border !important;
    padding: 0 0 16px 0 !important;
    margin-bottom: 8px;

    .ql-size {
        border: 1px solid var(--light-blue);
        width: 80px !important;
        height: 32px;

        .ql-picker-label {
            @include font('minorabold');
            font-size: 12px;
            color: $black;
            padding-left: 16px;

            &::before {
                line-height: 28px;
            }
        }
    }
}

.reaction-editor {
    .ql-container {
        background-color: var(--light-blue);
    }
}

.ql-container {
    font-family: inherit;
    border: none !important;

    .ql-editor {
        min-height: 125px;
        border-radius: $border-radius;

        p {
            @include font('robotoregular');
            color: $black;
            font-size: 15px;
            line-height: 30px;
        }

        img {
            max-width: 100%;
        }
    }

    .ql-formats {
        .fa-camera {
            transform: translateY(-1px);
        }
    }
}

.ql-editor[contenteditable='false'] {
    padding: 0;

    min-height: 75px;
}

#reaction-editor .ql-container .ql-editor {
    min-height: 135px;
}

.ql-container .ql-editor {
    min-height: auto;
}
.ql-mention-list-container {
    width: 270px;
    border: 1px solid #f0f0f0;
    border-radius: $border-radius;
    background-color: #ffffff;
    z-index: 9001;
}

.ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.ql-mention-list-item {
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    padding: 4px 24px;
    vertical-align: middle;
}

.ql-mention-list-item.selected {
    background-color: #f2f5f8;
    text-decoration: none;
}

.mention {
    @include font('roboto');
    height: 24px;
    width: 65px;
    border-radius: $border-radius;
    padding: 4px 0;
    margin-right: 2px;
    color: $black;
    font-weight: 700;
}

.mention > span {
    margin: 0 4px;
}

.ql-mention-list-item .position-relative {
    top: 5.5px;
    display: inline-flex;
}

.ql-size-small {
    font-size: 10px;
}

.ql-size-large {
    font-size: 20px;
    font-family: 'minorabold';
    color: $dark-blue;
}
