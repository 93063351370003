.ot-floating-button {
    width: 24px !important;
    height: 24px !important;
}

.body--authorized .ot-floating-button {
    @include media-breakpoint-down(xl) {
        @include transform(translate(15px, -85px) !important);
        margin-bottom: -50px;
        margin-left: -16px;
    }
}
