@import 'tools/variables';
@import 'tools/mixins';

.table-container {
    position: relative;
    overflow-x: auto;
}

.table {
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    display: table;

    &__row {
        &:nth-child(odd) {
            background-color: $white;
        }

        &:nth-child(even) {
            background-color: $white;
        }

        &--header {
            border-radius: $border-radius;
        }
    }

    &__seperator {
        display: none;
    }

    &__cell {
        padding: 8px 16px;

        .table__label {
            @include font('robotoregular');
            font-size: 12px;
            color: var(--dark-blue);
        }

        .table__link {
            color: var(--dark-blue);

            &:hover {
                color: var(--dark-blue);
            }
        }

        &--highlighted {
            background-color: $light-gray;

            .table__label {
                @include font('robotoregular');
                font-size: 12px;
                color: $white;
            }
        }

        &--header {
            background-color: var(--dark-blue);

            .table__label {
                @include font('minorabold');
                font-size: 14px;
                color: $white;
            }

            &:first-child {
                border-top-left-radius: $border-radius-element;
                border-bottom-left-radius: $border-radius-element;
            }

            &:last-child {
                border-top-right-radius: $border-radius-element;
                border-bottom-right-radius: $border-radius-element;
            }
        }

        &--start {
            text-align: start;
        }
    }

    &__index {
        @include font('robotoregular');
        font-size: 12px;
        padding: 4px 8px;
        color: $white;
        background-color: var(--dark-blue);

        &--highlighted {
            color: var(--dark-blue);
            background-color: $white;
        }
    }

    &__online-indicator {
        position: relative;
        margin-left: 8px;

        &:after {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: $border-radius-circle;
            position: absolute;
            bottom: 2px;
            background-color: var(--dark-green);
        }
    }

    &__content {
        &--minora {
            @include font('minorabold');
            font-size: 14px;
        }

        &--roboto {
            @include font('robotoregular');
            font-size: 12px;
            color: $light-gray;
        }
    }

    &--competition-standings {
        .table {
            &__row {
                border: unset;
                border-radius: unset;
                margin-bottom: 8px;

                &:nth-child(odd) {
                    background-color: $white;
                }

                &:nth-child(even) {
                    background-color: $light-blue;
                }

                &--header {
                    border: unset;
                }

                &--highlighted {
                    &:nth-child(odd) {
                        background-color: $dark-blue;
                    }

                    &:nth-child(even) {
                        background-color: $dark-blue;
                    }

                    .table__label,
                    .table__cell {
                        color: $white !important;
                    }
                }
            }

            &__cell {
                height: 32px;
                border-right: unset;
                padding: 4px 0px;

                &:first-child {
                    border-top-left-radius: $border-radius-element;
                    border-bottom-left-radius: $border-radius-element;
                    padding-left: 8px;
                }

                &:last-child {
                    border-top-right-radius: $border-radius-element;
                    border-bottom-right-radius: $border-radius-element;
                    padding-right: 8px;
                }

                &--header {
                    border: unset;
                    background-color: $white;
                    border-radius: unset;
                    height: 32px;

                    &:first-child {
                        text-align: start;
                        padding-left: 62px;
                    }

                    .table__label {
                        @include font('Roboto');
                        font-size: 16px;
                        color: $light-gray;
                    }
                }

                &__label {
                    @include font('Roboto');
                    font-size: 16px;
                    color: var(--dark-blue);

                    &--header {
                        @include font('Roboto');
                        font-size: 16px;
                        color: $light-gray;
                    }
                }
            }
        }
    }

    &--club-selection {
        .table__row {
            &:nth-child(odd) {
                background-color: $white;
            }

            &:nth-child(even) {
                background-color: $white;
            }
        }

        .table__cell {
            padding: 4px 0px;
            min-width: 32px;

            &:first-child {
                padding-left: 8px;
                border-top-left-radius: $border-radius-element;
                border-bottom-left-radius: $border-radius-element;
            }

            &:last-child {
                padding-right: 8px;
                border-top-right-radius: $border-radius-element;
                border-bottom-right-radius: $border-radius-element;
            }

            &:not(:last-child) {
                border-right: unset;
            }

            &--header {
                &:not(:last-child) {
                    border-right: unset;
                }
            }
        }
    }

    &--leaderboard {
        border-collapse: separate;
        border-spacing: 0 4px;
        width: 100%;

        &--spaced {
            border-spacing: 0 16px;
        }

        .table {
            &__row {
                border: 1px solid $border;
                border-radius: $border-radius;
                margin-bottom: 0;

                &--header {
                    border: unset;
                    background-color: unset;
                }
            }

            &__seperator-row {
                height: 4px;
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;

                &--first {
                    height: 10px;
                }

                &--large {
                    height: 8px;
                }
            }

            &__seperator-line {
                @include transform(translateX(-50%));
                width: 32px;
                height: 2px;
                background-color: $light-gray;
                opacity: 0.24;
                position: absolute;
                left: 50%;
            }

            &__cell {
                border: 1px solid $border;
                border-radius: $border-radius-element;
                height: 44px;

                &:first-child {
                    padding: 0;
                }

                &:not(:first-child) {
                    border-left: unset;
                }

                &:not(:last-child) {
                    border-right: unset;
                }

                &--header {
                    border: unset;
                    background-color: unset;
                    color: $dark-gray;
                    border-radius: unset;
                    padding: 0 16px;
                    height: 24px;

                    &:last-child {
                        padding-right: 16px;
                    }
                }

                &--highlighted {
                    background-color: var(--light-green);

                    .username-link {
                        color: $dark-gray;
                    }
                }
            }

            &__label {
                @include font('minorabold');
                font-size: 14px;
                color: var(--dark-blue);

                &--header {
                    font-size: 12px;
                }

                &--highlighted {
                    color: var(--dark-green);
                }

                &--danger {
                    color: $danger;
                }
            }

            &__index {
                @include font('minorabold');
                background-color: unset;
                color: var(--dark-blue);
            }
        }
    }

    &--leaderboard-ek, &--subleagues-ek {
        border-collapse: separate;
        border-spacing: 0 4px;
        width: 100%;

        &--spaced {
            border-spacing: 0 16px;
        }

        .table {
            &__row {
                border: 1px solid $border;
                border-radius: $border-radius;
                margin-bottom: 0;

                &:not(.table__row--header) {
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(0deg, rgba(255, 165, 0, 0.08) 0%, rgba(255, 165, 0, 0.08) 100%), #FFF;
                        cursor: pointer;
                    }
                }

                &--header {
                    border: unset;
                    background-color: unset;
                }
            }

            &__seperator-row {
                height: 4px;
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;

                &--first {
                    height: 10px;
                }

                &--large {
                    height: 8px;
                }
            }

            &__seperator-line {
                @include transform(translateX(-50%));
                width: 32px;
                height: 2px;
                background: rgba(255, 165, 0, 0.40);
                position: absolute;
                left: 50%;
            }

            &__cell {
                border: 1px solid $border;
                border-radius: $border-radius-element;
                height: 52px;
                padding: 0;

                @include media-breakpoint-down(lg) {
                    height: 48px;
                }

                &:first-child {
                    padding: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    @include media-breakpoint-up(lg) {
                        padding-right: 16px;
                    }
                }

                &:not(:first-child) {
                    border-left: unset;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &:not(:last-child) {
                    border-right: unset;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &--header {
                    border: unset;
                    background-color: unset;
                    color: $dark-gray;
                    border-radius: unset;
                    padding: 0 16px;
                    height: 24px;

                    &:last-child {
                        padding-right: 16px;
                    }
                }

                &--highlighted {
                    background: linear-gradient(0deg, rgba(255, 165, 0, 0.08) 0%, rgba(255, 165, 0, 0.08) 100%), #FFF;

                    .username-link {
                        color: $dark-gray;
                    }
                }
            }

            &__label {
                font-size: 12px;
                color: $light-gray;

                &--header {
                    @include font('robotoregular');
                    font-size: 12px;
                }

                &--highlighted {
                    color: var(--ek-orange);
                }
            }

            &__index {
                background-color: unset;
                color: var(--ek-blue);
                font-weight: 600;
            }

            &__rank {
                width: 52px;

                @include media-breakpoint-down(sm) {
                    width: 28px;
                }
            }

            &__rank-adjustment {
                width: 32px;

                @include media-breakpoint-down(sm) {
                    width: 8px;
                }
            }
        }
    }

    &--subleagues-ek {
        border-spacing: 0 8px;
    }

    &--blocked-list {
        border-collapse: separate;
        border-spacing: 0 16px;
        width: 100%;
        margin-top: -16px;

        .table {
            &__row {
                border: 1px solid $border;
                border-radius: $border-radius;
                margin-bottom: 0;
            }

            &__cell {
                border: 1px solid $border;
                border-radius: $border-radius-element;
                height: 44px;

                &:first-child {
                    padding: 0;
                }

                &:not(:first-child) {
                    border-left: unset;
                }

                &:not(:last-child) {
                    border-right: unset;
                }
            }
        }
    }

    &--home {
        border-collapse: separate;
        width: 99%;

        .table {
            &__row {
                margin-bottom: 8px;

                &--highlighted {
                    margin-bottom: 16px;
                }

                &--header {
                    border: unset;
                }

                &--gold {
                    background: $gold-gradient;

                    .table__cell {
                        &:first-child {
                            border: 1px solid $gold;
                            border-left: 4px solid $gold;
                        }

                        &:not(:first-child) {
                            border: 1px solid $gold;
                        }

                        &:last-child {
                            border: 1px solid $gold;
                        }
                    }
                }

                &--silver {
                    background: $silver-gradient;

                    .table__cell {
                        &:first-child {
                            border: 1px solid $silver;
                            border-left: 4px solid $silver;
                        }

                        &:not(:first-child) {
                            border: 1px solid $silver;
                        }

                        &:last-child {
                            border: 1px solid $silver;
                        }
                    }
                }

                &--bronze {
                    background: $bronze-gradient;

                    .table__cell {
                        &:first-child {
                            border: 1px solid $bronze;
                            border-left: 4px solid $bronze;
                        }

                        &:not(:first-child) {
                            border: 1px solid $bronze;
                        }

                        &:last-child {
                            border: 1px solid $bronze;
                        }
                    }
                }

                .table__cell {
                    &:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        border-right: unset;
                    }

                    &:not(:first-child):not(:last-child) {
                        border-left: unset;
                        border-right: unset;
                    }

                    &:last-child {
                        border-left: unset;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            &__seperator-row {
                height: 4px;
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;

                &--first {
                    height: 10px;
                }

                &--large {
                    height: 8px;
                }
            }

            &__seperator-line {
                width: 32px;
                height: 2px;
                background-color: var(--dark-blue);
                opacity: 0.24;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            &__cell {
                height: 64px;

                &:first-child {
                    padding: 0;
                }

                &:not(:last-child) {
                    border-right: unset;
                }

                &--header {
                    border: unset;
                    background-color: $white;
                    color: $dark-gray;
                    border-radius: unset;
                    padding: 0 16px;
                    height: 24px;

                    &:last-child {
                        padding-right: 16px;
                    }
                }

                &--highlighted {
                    background-color: var(--light-green);

                    .username-link {
                        color: $dark-gray;
                    }
                }
            }

            &__label {
                @include font('minorabold');
                font-size: 14px;
                color: var(--dark-blue);

                &--header {
                    font-size: 12px;
                }

                &--highlighted {
                    color: var(--dark-green);
                }
            }

            &__index {
                @include font('minorabold');
                background-color: unset;
                color: var(--dark-blue);
            }
        }
    }

    &--list {
        border-collapse: separate;
        border-spacing: 0 16px;
        white-space: normal;
    }

    &--ek {
        box-shadow: 8px 8px 32px 0px rgba(0, 0, 0, 0.24);
        border: 1px solid $border;

        thead {
            &:first-child {
                        .table__cell {
                            &:first-child {
                                border-top-left-radius: 8px;
                                border-right: unset;
                            }

                            &:not(:first-child):not(:last-child) {
                                border-left: unset;
                                border-right: unset;
                            }

                            &:last-child {
                                border-left: unset;
                                border-top-right-radius: 8px;
                            }

                }
            }
        }

        tbody {
            &:last-child {
                .table__cell {

                    &:first-child {
                        border-bottom-left-radius: 8px;
                        border-right: unset;
                    }

                    &:not(:first-child):not(:last-child) {
                        border-left: unset;
                        border-right: unset;
                    }

                    &:last-child {
                        border-left: unset;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }


        .table__row {
            height: 26px;
            padding: 0 16px;
            border: 1px solid $border;
            color: $ek-blue;
            background-color: $white;
            border-radius: 0;

            &--header {
                background-color: $ek-orange;
                color: $white;
                font-size: 12px;
                font-weight: 600;
            }

            .table__cell {
                font-size: 12px;
                height: 26px;
                padding: 0 16px;
                text-wrap: balance;
            }
        }


    }
}

.table-container--list {
    container-type: inline-size;
}
