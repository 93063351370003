:root {
    --dark-blue: #{$dark-blue};
    --dark-green: #{$dark-green};
    --medium-blue: #{$medium-blue};
    --medium-green: #{$medium-green};
    --light-blue: #{$light-blue};
    --light-green: #{$light-green};
    --dark-blue-accent: #{$dark-blue-accent};
    --dark-green-accent: #{$dark-green-accent};
}

.theme-ek-2024 {
    --dark-blue: #{$ek-blue};
    --dark-green: #{$ek-orange};
    --light-green: #{$light-green};
    --dark-blue-accent: #{$dark-blue-accent};
    --dark-green-accent: #{$ek-orange-accent};
}
