article {
    // Header margins if not first element
    h2:not(:first-child) {
        margin-top: 32px;
    }

    h3:not(:first-child) {
        margin-top: 24px;
    }

    h4:not(:first-child) {
        margin-top: 16px;
    }

    h5:not(:first-child),
    h6:not(:first-child) {
        margin-top: 8px;
    }

    // Paragraphs when no other class is given or is this class by some api we use
    p:not([class]),
    p[class='ng-star-inserted'] {
        font-size: 14px;
        color: $dark-gray;
        margin-top: 8px;

        img:not([class]) {
            max-width: 100%;
            height: auto;
            margin-top: 8px;
            border-radius: $border-radius-element;
        }
    }

    a:not([class]) {
        font-size: 14px;
        text-decoration: none;
        color: $dark-green;

        &:hover {
            color: $dark-green-accent;
        }
    }

    // Lists when no other class is given
    ul:not([class]) {
        color: $dark-gray;
        list-style: disc;
        margin-top: 16px;

        li {
            margin-bottom: 0;
            margin-left: 16px;
            font-size: 14px;
            color: $dark-gray;

            p {
                margin-bottom: 0;
            }
        }
    }

    ol:not([class]) {
        color: $dark-gray;
        margin-top: 16px;

        li {
            margin-bottom: 8px;
            font-size: 14px;
            color: $dark-gray;

            &:last-child {
                margin-bottom: 0;
            }
            p {
                margin-bottom: 0;
            }
        }
    }

    // Custom table convertion and instantiated holder to handle overflow scrolling
    .table-holder {
        overflow: auto;
        width: 100%;
        position: relative;
    }

    table:not([class]) {
        overflow: hidden;
        border-radius: 2px;
        white-space: nowrap;
        margin-top: 24px;

        tbody {
            tr {
                height: 50px !important;

                &:first-child {
                    background-color: $dark-blue;

                    td {
                        @include font('minorabold');
                        border-color: $white;
                        color: $white;

                        &:first-child {
                            border-top-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                        }

                        > p,
                        a {
                            @include font('minorabold');
                            color: $white;
                        }
                    }
                }

                &:last-child {
                    td {
                        &:first-child {
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }

                td {
                    color: $dark-gray;
                    height: 50px !important;
                    border: 1px solid $border;
                    font-size: 12px !important;
                    padding-left: 16px;

                    > br {
                        display: none;
                    }

                    p,
                    a {
                        font-size: 12px !important;
                    }

                    p {
                        margin-bottom: 0;
                        padding: 16px;
                    }

                    a {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    iframe {
        width: 100%;
        margin-top: 8px;
    }

    blockquote {
        background-color: var(--light-blue);
        color: $dark-gray;
        width: 100%;
        padding: 16px;
        text-align: center;
        margin-top: 24px;
    }

    .article-affiliate-link {
        @include font('minorabold');
        cursor: pointer;
        position: relative;
        border: none;
        text-align: center;
        min-height: 45px;
        padding: 12px 24px;
        border-radius: $border-radius;
        background-color: var(--dark-green);
        color: $white;
        text-decoration: none;
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        a,
        span {
            color: $white;
        }

        &:focus {
            outline: 0;
        }

        &:hover,
        &:disabled {
            text-decoration: none;
            background-color: var(--dark-green-accent);
            color: $white;
        }
    }
}
