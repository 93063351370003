@import 'tools/variables';
@import 'tools/mixins';

// Shadows
.drop-shadow-light {
    @include drop-shadow($shadow-light);
}

.drop-shadow-dark {
    @include drop-shadow($shadow-dark);
}

.drop-shadow {
    @include drop-shadow($shadow);
}

.box-shadow-light {
    @include box-shadow($shadow-light);
}

.box-shadow-dark {
    @include box-shadow($shadow-dark);
}

.box-shadow {
    @include box-shadow($shadow);
}

.text-shadow-light {
    @include text-shadow($shadow-light);
}

.text-shadow-dark {
    @include text-shadow($shadow-dark);
}

.text-shadow {
    @include text-shadow($shadow);
}

.cube-micro {
    width: 8px;
    height: 8px;

    &--force {
        min-width: 8px;
        min-height: 8px;
    }
}

.cube-micro-plus {
    width: 12px;
    height: 12px;
}

.cube-tiny {
    width: 16px;
    height: 16px;

    &--force {
        min-width: 16px;
        min-height: 16px;
    }
}

.cube-tiny-plus {
    width: 20px;
    height: 20px;
}

.cube-small {
    width: 24px;
    height: 24px;
}

.cube-medium {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.cube-medium-plus {
    width: 40px;
    height: 40px;
}

.cube-large {
    width: 48px;
    height: 48px;
}

.cube-large-plus {
    width: 56px;
    height: 56px;
}

.cube-jumbo {
    width: 64px;
    height: 64px;
}

.cube-jumbo-plus {
    width: 72px;
    height: 72px;
}

.cube-epic {
    width: 80px;
    height: 80px;
}

.pointer-events-none {
    pointer-events: none;
}

.overflow-x-auto {
    overflow-x: auto;
}
