@import 'tools/variables';
@import 'tools/mixins';

.dropdown {
    cursor: pointer;
}

.dropdown-menu {
    @include transition(opacity 0.2s ease, visibility 0.3s ease);
    min-width: 208px;
    visibility: hidden;
    opacity: 0;
    display: block;
    border: 0;
    border-radius: $border-radius;
    inset: 100% 0px auto 0px;

    &.open {
        opacity: 1;
    }

    .dropdown-item {
        @include transition(background-color 0.2s ease);
        font-size: 14px;
        padding: rem-calc(10 20);
        color: $dark-gray;
        line-height: 1.2;
        white-space: normal;

        @include media-breakpoint-up(xl) {
            font-size: 13px;
            padding: rem-calc(12 20);
        }

        &:hover {
            background-color: var(--light-blue);
        }
    }

    a {
        &.dropdown-item {
            &:hover {
                background-color: var(--light-blue);
            }
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }
}
