@import '../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss';
@import 'tools/variables';
@import 'tools/mixins';

bs-datepicker-inline-container {
    min-width: 100%;
}

bs-datepicker-calendar-view,
bs-days-calendar-view,
bs-month-calendar-view,
bs-years-calendar-view,
.bs-media-container,
.bs-datepicker-container {
    padding: 0;
    width: 100%;
    padding: 0;
}

.bs-datepicker {
    width: 100%;
    border: 0;
    box-shadow: none;
    margin-bottom: 8px;

    span {
        @include font('Roboto');
    }
    .bs-datepicker-head {
        min-width: unset;
        background-color: $white;
        border-top: 1px solid $border;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        border-top-left-radius: $border-radius-element;
        border-top-right-radius: $border-radius-element;

        button {
            color: $black;
        }
    }

    .bs-datepicker-body {
        min-width: unset;
        min-height: unset;
        border: unset;
        border-bottom: 1px solid $border;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        border-bottom-left-radius: $border-radius-element;
        border-bottom-right-radius: $border-radius-element;


        table {
            thead {
                th {
                    &:first-child {
                        display: none;
                    }
                }
            }

            th {
                font-size: 10px;
            }

            td {
                span.selected {
                    background-color: var(--dark-blue);

                    &:hover {
                        background-color: var(--dark-blue-accent);
                    }
                }

                &.week {
                    display: none;

                    span {
                        color: var(--dark-blue);
                        visibility: hidden;
                    }
                }
            }
        }

        table.days {
            span {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }
        }

        .bs-datepicker-today {
            position: relative;
            font-weight: 800;

            &::after {
                content: '*';
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .bs-datepicker-buttons {
        padding-top: 16px;
        margin-bottom: 16px;

        .btn-today-wrapper {
            button {
                @include font('minorabold');
                font-size: 12px;
                background-color: var(--dark-blue);
                border-color: var(--dark-blue);
                color: $white;
                padding: 8px 16px;
                border-radius: $border-radius-element;
                width: 200px;

                &:hover {
                    background-color: var(--dark-blue-accent);
                }
            }
        }
    }
}

.date-picker--absolute .bs-datepicker {
    box-shadow: 0px 0px 12px 0px rgba(10, 40, 57, 0.24);
    border-radius: $border-radius-element;
}

timepicker {
    tr {
        td {
            a.btn-link {
                display: inline-table;
            }
        }
    }
}
