// Any changes to HTML/body tags should adhere to:
// - Navigating to other page should reset scroll with scrollRestorationEnabled
// - The Vertical page scroll-indicator should work correctly
// - Horizontal scrollable containers (including progress indicator) should work correctly
// - On IOS specifically, there should not be any page-wide horizontal scrolling
// - Mobile version of /chat and the chat popup should fill up the available space, input to be visible, and messages to be scrolled through fully

.html {
    height: 100%; // Required for tracking vertical scroll position for our scroll indicator
    overflow-x: hidden; // Required in part to fix the Iphone Safari site-wide horizontal scrolling issue
}

.body {
    background-color: $background;
    color: $black;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; // Required in part to fix the Iphone Safari site-wide horizontal scrolling issue
    height: auto; // Avoid triggering the scroll event on the body element as done per a 100% value

    &--chat {
        height: calc(100% - 70px);

        @include media-breakpoint-up(sm) {
            height: calc(100% - 60px);
        }
    }
}
