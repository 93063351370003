@import 'tools/variables';
@import 'tools/mixins';

.modal {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);

    &:not(.lightbox-modal) {
        // Fill screen on mobile
        @include media-breakpoint-down(sm) {
            .modal-dialog {
                height: 100%;
                margin: 0;
                flex-direction: column;
            }

            .modal-content {
                min-height: 100%;
            }

            .modal-dialog-centered {
                display: block;
            }

            .modal-video {
                display: flex;
                height: 100%;
                min-height: 0%;
                justify-content: center;

                .modal-content {
                    min-height: 0%;
                    height: auto;
                }
            }
        }
    }

    // Custom padding handler for tip-modal as it requires a full-width background color change
    .tip-modal {
        .modal-content {
            width: 100%;
        }

        .modal-page {
            width: 100%;
        }

        .modal-page__body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .tip-modal-padding-handler {
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 40px;
            }
        }

        @include media-breakpoint-up(sm) {
            .modal-content {
                width: 800px;
            }

            .modal-page {
                width: 800px;
            }
        }
    }

    // Custom padding handler for simple-tip-modal as it requires a full-width background color change
    .simple-tip-modal {
        .modal-page__body {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            .simple-tip-modal-padding-handler {
                padding-left: 24px;
                padding-right: 24px;
                padding-bottom: 40px;
            }
        }
    }

    .modal-content {
        border: 0;
        border-radius: 8px;
    }

    .dropdown {
        .dropdown-menu {
            right: 0px !important;
            left: auto !important;
        }
    }
}
