@import 'scss/functions';
@import 'scss/variables';
@import 'scss/mixins';

// >> FONTS
$font-family-minora: minorabold, Arial, Helvetica, 'sans-serif';
$font-sizes: (
    6: 6px,
    micro: 8px,
    tiny: 10px,
    small: 12px,
    medium: 14px,
    large: 16px,
    jumbo: 18px,
    epic: 20px,
    22: 22px,
    24: 24px,
    26: 26px,
    28: 28px,
    32: 32px,
    36: 36px,
    40: 40px,
    48: 48px,
);

// >> GRID DETECTION
$grid-breakpoints: (
    min: 0,
    xs: 380px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    ul: 2560px,
);

// >> CONTAINER SIZE
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    ul: 1640px,
);

// >> WHITESPACE
$spacers: (
    0: 0,
    2: 2px,
    4: 4px,
    6: 6px,
    7: 7px,
    8: 8px,
    11: 11px,
    12: 12px,
    15: 15px,
    16: 16px,
    24: 24px,
    32: 32px,
    40: 40px,
    48: 48px,
    56: 56px,
    64: 64px,
    72: 72px,
    80: 80px,
    120: 120px,
);

// >> NEGATIVE SPACE
$negative-spacers: (
    0: 0,
    6: -6px,
    8: -8px,
    16: -16px,
    24: -24px,
    32: -32px,
    40: -40px,
);

// >> ELEMENT SIZE
$element-sizes: (
    16: 16px,
    24: 24px,
    32: 32px,
    40: 40px,
    45: 45px,
    48: 48px,
    100: 100%,
    fit-content: fit-content,
    max-content: max-content,
    auto: auto,
    unset: unset,
);

// >> MAIN COLORS
$white: #fff;
$black: #000;

// >> THEME COLORS
$dark-blue: #0a2839;
$dark-green: #3cc3ab;
$medium-blue: #e9f5fb;
$medium-green: #ecf9f7;
$light-blue: #f6fbfd;
$light-green: #f0faf7;
$dark-blue-accent: #0e374e;
$dark-green-accent: #36af9a;

// >> REGULAR COLORS
$yellow: #fadb14;
$orange: #eb6900;
$purple: #9747ff;

// >> GRAYSCALE COLORS
$dark-gray: #575757;
$light-gray: #999999;
$background: #f5f5f5;
$background-accent: #eaeaea;
$border: #f2f2f2;
$dark-gray-accent: #9999; // - Accent for black, dark's
$light-gray-accent: #f2f2f2; // Accent for secondary, white, medium and lights

// >> FEEDBACK COLORS
$success: #2fb668;
$danger: #f35454;
$info: #0d58c2;
$warning: #ffb300;
$success-accent: #2aa25d;
$danger-accent: #f13c3c;
$info-accent: #0c4eac;
$warning-accent: #e5a100;

// >> RANKING COLORS
$gold: #fed986;
$silver: #9c9c9c;
$bronze: #cb9a8e;
$gold-accent: #fed16c;
$silver-accent: #8f8f8f;
$bronze-accent: #c48c7e;
$amateur: #c2723d;
$talent: #8d8d8d;
$professional: #fcb51d;
$icon: #c33c3c;
$legend: #3cc3ab;

// >> SOCCERNEWS COLORS
$soccernews-light: #c7ebd5;
$soccernews-medium: #4aa36c;
$soccernews-dark: #2e6643;

// >> GAME COLORS
$ek-orange: #ffa500;
$ek-orange-accent: #df9712;
$ek-blue: #001337;

// >> SKELETON COLORS
$skeletonizeFG: #e5e5e5;
$skeletonizeBG: #f8f8f8;

// >> DISCORD COLORS
$discord: #5865f2;
$discord-accent: #7289da;

// >> SPORT COLORS
$football: #3cc3ab;
$tennis: #c23b22;
$darts: #0068b3;
$basketball: #188da5;
$volleyball: #188da5;
$rugby: #6b0626;
$iceskating: #cbecff;
$cycling: #f3be1f;
$motorsport: #e10600;
$fighting: #212327;
$snooker: #36594a;

// >> BOOKMAKER COLORS
$b-bet365: #00865f;
$b-onecasino: #5fff90;
$b-unibet: #147b45;
$b-bet777: #891613;
$b-livescore-bet: #fd5c3c;
$b-jacks-casino-sport: #dc0812;
$b-circus: #df2025;
$b-leovegas: #ff6b00;
$b-bingoal: #e2001a;
$b-kansino: #1830b9;
$b-betcity: #00f6ff;
$b-holland-casino-sport: #1b334d;
$b-toto: #187e3c;
$b-bet711: #ff2323;
$b-betnation: #1a3174;
$b-comeon: #39f56a;
$b-betsson: #ff6600;
$b-interwetten: #ffda3e;

// >> COLOR CLASSES CREATION
$colors: (
    dark-gray: $dark-gray,
    light-gray: $light-gray,
    white: $white,
    black: $black,
    dark-blue: var(--dark-blue),
    dark-green: var(--dark-green),
    medium-blue: var(--medium-blue),
    medium-green: var(--medium-green),
    light-green: var(--light-green),
    light-blue: var(--light-blue),
    border: $border,
    background: $background,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info,
    gold: $gold,
    silver: $silver,
    bronze: $bronze,
    transparent: transparent,
    dark-gray-accent: $dark-gray-accent,
    light-gray-accent: $light-gray-accent,
    dark-blue-accent: $dark-blue-accent,
    dark-green-accent: $dark-green-accent,
    success-accent: $success-accent,
    danger-accent: $danger-accent,
    info-accent: $info-accent,
    warning-accent: $warning-accent,
    gold-accent: $gold-accent,
    silver-accent: $silver-accent,
    bronze-accent: $bronze-accent,
    amateur: $amateur,
    talent: $talent,
    professional: $professional,
    icon: $icon,
    legend: $legend,
    soccernews-light: $soccernews-light,
    soccernews-medium: $soccernews-medium,
    soccernews-dark: $soccernews-dark,
    football: $football,
    tennis: $tennis,
    darts: $darts,
    basketball: $basketball,
    volleyball: $volleyball,
    rugby: $rugby,
    iceskating: $iceskating,
    cycling: $cycling,
    motorsport: $motorsport,
    fighting: $fighting,
    snooker: $snooker,
    ek-orange: $ek-orange,
    ek-orange-accent: $ek-orange-accent,
    ek-blue: $ek-blue,
    background-accent: $background-accent,
    discord: $discord
);

// >> GRADIENTS
$ek-orange-gradient: linear-gradient(
        0deg,
        rgba(255, 165, 0, 0.08) 0%,
        rgba(255, 165, 0, 0.08) 100%
    ),
    #fff;
$ek-gray-gradient: linear-gradient(
        0deg,
        rgba(0, 19, 55, 0.02) 0%,
        rgba(0, 19, 55, 0.02) 100%
    ),
    #fff;

$dark-blue-gradient: linear-gradient(0deg, var(--dark-blue), var(--dark-blue)),
    var(--dark-blue-accent);
$dark-green-gradient: linear-gradient(
    to right,
    var(--dark-green) 0%,
    var(--dark-green-accent) 100%
);

$gold-gradient: linear-gradient(
    90deg,
    rgba(254, 217, 134, 0.24) 0%,
    rgba(254, 217, 134, 0) 30.73%
);

$silver-gradient: linear-gradient(
    90deg,
    rgba(156, 156, 156, 0.24) 0%,
    rgba(156, 156, 156, 0) 30.73%
);

$bronze-gradient: linear-gradient(
    90deg,
    rgba(203, 154, 142, 0.24) 0%,
    rgba(203, 154, 142, 0) 30.73%
);

// >> GRADIENT CLASS CREATION
$gradients: (
    ek-orange-gradient: $ek-orange-gradient,
    ek-gray-gradient: $ek-gray-gradient,
    dark-blue-gradient: $dark-blue-gradient,
    dark-green-gradient: $dark-green-gradient,
    gold-gradient: $gold-gradient,
    silver-gradient: $silver-gradient,
    bronze-gradient: $bronze-gradient,
);

// >> GENERAL
$border-width: 1px;
$border-color: $border;

$border-radius: 4px;
$border-radius-element: 8px;
$border-radius-block: 12px;
$border-radius-pill: 50rem;
$border-radius-circle: 50%;

$lh-xs: 12px;
$lh-sm: 18px;
$lh-base: 24px;
$lh-lg: 32px;

$shadow-light: 10px 10px 50px rgba(0, 0, 0, 0.04);
$shadow-dark: 4px 4px 24px rgba(10, 40, 57, 0.08);
$shadow: 0px 0px 12px rgba(10, 40, 57, 0.24);
$modal-md: 600px;

// >> FORMS
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$dark-blue}'/></svg>");
$form-check-input-checked-bg-color: var(--dark-blue);
$form-check-input-checked-border-color: var(--dark-blue);
$form-check-input-focus-box-shadow: unset;
