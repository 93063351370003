@-webkit-keyframes blink {
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    43% {
        opacity: 1;
    }
    45% {
        opacity: 0.2;
    }
    46% {
        opacity: 1;
    }
}
@keyframes blink {
    40% {
        opacity: 1;
    }
    42% {
        opacity: 0.8;
    }
    43% {
        opacity: 1;
    }
    45% {
        opacity: 0.2;
    }
    46% {
        opacity: 1;
    }
}

@-webkit-keyframes buzz {
    70% {
        opacity: 0.8;
    }
}

@keyframes buzz {
    70% {
        opacity: 0.8;
    }
}

@keyframes letterblink {
    78% {
        color: inherit;
        text-shadow: inherit;
    }
    79% {
        color: #333;
    }
    80% {
        text-shadow: none;
    }
    81% {
        color: inherit;
        text-shadow: inherit;
    }
    82% {
        color: #333;
        text-shadow: none;
    }
    83% {
        color: inherit;
        text-shadow: inherit;
    }
    92% {
        color: #333;
        text-shadow: none;
    }
    92.5% {
        color: inherit;
        text-shadow: inherit;
    }
}

@keyframes upvote {
    40% {
        @include transform(rotate(30deg));
    }
    42% {
        @include transform(rotate(0));
    }
    43% {
        @include transform(rotate(-30deg));
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0
            hsla(
                var(--dark-green-h),
                var(--dark-green-s),
                var(--dark-green-l),
                0.7
            );
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        @include transform(translate3d(2000px, 0, 0) scaleX(2));
        opacity: 0;
    }

    70% {
        @include transform(translate3d(-25px, 0, 0) scaleX(1));
        opacity: 1;
    }

    to {
        @include transform(translate3d(0, 0, 0));
    }
}

@keyframes bounceOutRight {
    to {
        @include transform(translate3d(2000px, 0, 0) scaleX(2));
        opacity: 0;
    }
}
